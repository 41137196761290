import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const TicketPrices = () => {
  const [adultPrice, setAdultPrice] = useState(null);
  const [kidPrice, setKidPrice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    // Fetch prices from the server
    fetch('https://bijuwarsp.bluebugsoft.com/backend/get_prices.php') // Adjust the URL as needed
      .then(response => response.json())
      .then(data => {
        setAdultPrice(data.adult_price);
        setKidPrice(data.kid_price);
        setLoading(false);
      })
      .catch(err => {
        setError('Failed to fetch prices');
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="ticket-prices">
      <h2 className="section-heading">Ticket Prices</h2>
      <p>Adults: Rs. {adultPrice}</p>
      <p>Kids: Rs. {kidPrice}</p>
      <button onClick={() => navigate('/tickets-price')}>Update Prices</button>
    </div>
  );
};

export default TicketPrices;
