import React, { useState, useEffect } from 'react';

const TicketingForm = ({ setActiveSessions }) => {
  const [name, setName] = useState('');
  const [adults, setAdults] = useState(0); // Set initial value to 1 to avoid zero
  const [kids, setKids] = useState(0);
  const [adultPrice, setAdultPrice] = useState(125); // Default value, will be updated from API
  const [kidPrice, setKidPrice] = useState(125); // Default value, will be updated from API
  const [totalPrice, setTotalPrice] = useState(0);
  const [duration, setDuration] = useState(1); // Default to 1 hour

  useEffect(() => {
    // Fetch ticket prices from the server
    fetch('https://bijuwarsp.bluebugsoft.com/backend/get_prices.php') // Adjust the URL as needed
      .then(response => response.json())
      .then(data => {
        setAdultPrice(data.adult_price);
        setKidPrice(data.kid_price);
      })
      .catch(error => {
        console.error('Error fetching prices:', error);
      });
  }, []);

  useEffect(() => {
    // Calculate the total price based on the number of people and duration
    const price = ((adults * adultPrice) + (kids * kidPrice)) * duration;
    setTotalPrice(price);
  }, [adults, kids, adultPrice, kidPrice, duration]);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Check if either adults or kids is greater than 0
    if (adults <= 0 && kids <= 0) {
      alert('Please enter at least one adult or one kid.');
      return;
    }

    const now = new Date();
    // Convert to Nepal Standard Time (UTC+5:45)
    const nptOffset = 5 * 60 + 45; // 5 hours and 45 minutes in minutes
    const localOffset = now.getTimezoneOffset();
    const totalOffset = nptOffset + localOffset;
    
    const nptDate = new Date(now.getTime() + totalOffset * 60000);
    
    const startTime = nptDate.toISOString();
    const endTime = new Date(nptDate.getTime() + duration * 60 * 60 * 1000).toISOString();


    const newSession = {
      name,
      people: `${adults + kids}`,
      startTime,
      endTime,
    };

    // Store session data in the database
    fetch('https://bijuwarsp.bluebugsoft.com/backend/store_session.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        adults,
        kids,
        total_price: totalPrice,
        start_time: startTime,
        end_time: endTime,
      }),
    })
      .then(response => response.text())
      .then(data => {
        console.log(data);
        setActiveSessions(prevSessions => {
          const updatedSessions = [...prevSessions, newSession];
          // Update localStorage
          localStorage.setItem('activeSessions', JSON.stringify(updatedSessions));
          return updatedSessions;
        });
      })
      .catch(error => {
        console.error('Error storing session:', error);
      });

    printPOSReceipt(newSession);

    // Reset form values
    setName('');
    setAdults(1); // Reset to default value
    setKids(0);
    setDuration(1); // Reset to default value
  };

  const printPOSReceipt = (session) => {
    const printContent = document.createElement('div');
    printContent.innerHTML = `
      <style>
        @page {
          size: 80mm auto;
          margin: 0;
        }
        body {
          font-family: 'Courier New', monospace;
          width: 80mm;
          padding: 5mm;
        }
        .center { text-align: center; }
        .bold { font-weight: bold; }
        hr { border-top: 1px dashed #000; }
      </style>
      <div class="center bold" style="font-size:24px">Bijuwar Skate Park</div>
      <div class="center bold">**Play At Your Own Risk.**</div>
      <hr>
      <div>Date: ${new Date().toLocaleString()}</div>
      <div>Customer: <strong>${session.name}</strong></div>
      <div>Adults: ${adults} x Rs. ${adultPrice}</div>
      <div>Kids: ${kids} x Rs. ${kidPrice}</div>
      <div>Total People: ${adults + kids}</div>
      <div>Duration: ${duration} hour(s)</div>
      <div>Start Time: ${new Date(session.startTime).toLocaleTimeString()}</div>
      <div>End Time: ${new Date(session.endTime).toLocaleTimeString()}</div>
      <div class="bold">Total Price: Rs. ${totalPrice}</div>
      <hr>
      <div class="center">Thank you for your visit!</div>
      <p class="center" style="font-size: 14px;">**The paid amount is not refundable**</p>
      <p class="center" style="font-size: 14px;">Powered by Bluebug Software.</p>
    `;

    const printWindow = window.open('', '', 'width=300,height=600');
    printWindow.document.open();
    printWindow.document.write(printContent.innerHTML);
    printWindow.document.close();

    printWindow.onload = function () {
      printWindow.print();
      printWindow.onafterprint = function () {
        printWindow.close();
      };
    };
  };

  return (
    <div className="ticketing-form box-white">
      <h2 className='section-heading'>Ticketing</h2>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <div>
            <label htmlFor="name">Enter Customer Name :</label>
            <input
              type="text"
              id="name"
              placeholder="Eg : Uttam Karki"
              value={name}
              onChange={(e) => setName(e.target.value)} required
            />
          </div>
        </div>

        <div className='form-group'>
          <div>
            <label htmlFor="adult">Number of Adults :</label>
            <input
              type="number"
              id='adult'
              placeholder="Adult Numbers"
              value={adults}
              onChange={(e) => {
                const value = Math.max(0, parseInt(e.target.value) || 0);
                setAdults(value);
              }} required
              min="0" // Ensures minimum value is 1
            />
          </div>

          <div>
            <label htmlFor="kid">Number of Kids :</label>
            <input
              type="number"
              placeholder="Kids"
              id='kid'
              value={kids}
              onChange={(e) => {
                const value = Math.max(0, parseInt(e.target.value) || 0);
                setKids(value);
              }} required
              min="0" // Ensures minimum value is 0
            />
          </div>
        </div>

        <div className='form-group'>
          <div>
            <label htmlFor="duration">Select Duration (In Hours):</label>
            <select
              id="duration"
              value={duration}
              onChange={(e) => setDuration(parseInt(e.target.value))}
            >
              <option value="1">1 hour</option>
              <option value="2">2 hours</option>
              <option value="3">3 hours</option>
              <option value="4">4 hours</option>
              <option value="5">5 hours</option>
              <option value="6">6 hours</option>
              <option value="7">7 hours</option>
              <option value="8">8 hours</option>
            </select>
          </div>
        </div>

        <div className='total-price'>Total price: Rs. {totalPrice}</div>
        <button type="submit">Confirm</button>
      </form>
    </div>
  );
};

export default TicketingForm;
