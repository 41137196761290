import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import ActiveSession from './components/ActiveSession';
import TicketsPrice from './components/TicketsPrice';
import BalanceSheet from './components/BalanceSheet';
import Support from './components/Support';
import Login from './components/Login';

function App() {
  const [activeSessions, setActiveSessions] = useState(() => {
    const savedSessions = localStorage.getItem('activeSessions');
    return savedSessions ? JSON.parse(savedSessions) : [];
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [usertype, setUsertype] = useState('');

  useEffect(() => {
    localStorage.setItem('activeSessions', JSON.stringify(activeSessions));
  }, [activeSessions]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = new Date().toISOString();
      const updatedSessions = activeSessions.filter(session => session.endTime > currentTime);
      if (updatedSessions.length !== activeSessions.length) {
        setActiveSessions(updatedSessions);
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, [activeSessions]);

  useEffect(() => {
    const isAuth = localStorage.getItem('isAuthenticated');
    const storedUsertype = localStorage.getItem('usertype');
    if (isAuth === 'true') {
      setIsAuthenticated(true);
      setUsertype(storedUsertype);
    }
  }, []);

  const handleLogin = () => {
    const storedUsertype = localStorage.getItem('usertype'); // Ensure 'usertype' is used
    localStorage.setItem('isAuthenticated', 'true');
    setIsAuthenticated(true);
    setUsertype(storedUsertype); // Set the usertype state
  };

  return (
    <Router>
      <div className="App">
        {!isAuthenticated ? (
          <Routes>
            <Route path="*" element={<Navigate to="/login" replace />} />
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
          </Routes>
        ) : (
          <>
            <Sidebar />
            <hr />
            <div className="content">
              <Routes>
                <Route path="/" element={<Dashboard activeSessions={activeSessions} setActiveSessions={setActiveSessions} />} />
                <Route path="/active-session" element={<ActiveSession activeSessions={activeSessions} />} />
                <Route path="/tickets-price" element={<TicketsPrice />} />
                <Route path="/balance-sheet" element={<BalanceSheet user_type={usertype} />} /> {/* Pass user_type here */}
                <Route path="/support" element={<Support />} />
                <Route path="/login" element={<Navigate to="/" replace />} />
              </Routes>
            </div>
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
