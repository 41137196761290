import React, { useState } from 'react';
import bgImage from '../Assets/bg.png';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://bijuwarsp.bluebugsoft.com/backend/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (data.status === 'success') {
        // Store authentication status and usertype in localStorage
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('usertype', data.user_type); // Ensure 'user_type' is used
        onLogin();
      } else {
        setError(data.message || 'Invalid credentials');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-form" style={{ backgroundImage: `url(${bgImage})` }}>
      <div className='login-content'>
        <h3 className='login-heading'>Bijuwarsp Skating Park</h3>
        <h2 className='desc'>Please log in using your username and password.</h2>
      </div>
      <div className='form'>
        <h2 className='section-heading'>Login</h2>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="username">Username:</label>
            <br />
            <input className='input'
              id='username'
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor='password'>Password:</label>
            <br />
            <input className='input'
              type={showPassword ? 'text' : 'password'}
              value={password}
              id='password'
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div>
              <input
                type="checkbox"
                id="showPassword"
                checked={showPassword}
                onChange={togglePasswordVisibility}
              />
              <label htmlFor="showPassword">Show Password</label>
            </div>
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
