import React, { useState } from 'react';

const TicketPrices = () => {
  const [adultPrice, setAdultPrice] = useState(125);
  const [kidPrice, setKidPrice] = useState(125);
  const [message, setMessage] = useState('');

  const handleAdultPriceChange = (e) => {
    setAdultPrice(e.target.value);
  };

  const handleKidPriceChange = (e) => {
    setKidPrice(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create data object
    const data = {
      adultPrice,
      kidPrice
    };

    // Send POST request to PHP script
    fetch('https://bijuwarsp.bluebugsoft.com/backend/update_prices.php', { // Adjust the path as needed
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(result => {
        if (result.status === 'success') {
          setMessage('Prices updated successfully!');
        } else {
          setMessage('Failed to update prices: ' + result.message);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setMessage('An error occurred.');
      });
  };

  return (
    <div className="ticket-prices-page">
      <h2 className="section-heading">Ticket Prices</h2>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <div>
            <label htmlFor="adultPrice">Adults : </label> <br></br>
            <input
              type="number"
              id="adultPrice"
              value={adultPrice}
              onChange={handleAdultPriceChange}
            />
          </div>
          <div>
            <label htmlFor="kidPrice">Kids : </label> <br></br>
            <input
              type="number"
              id="kidPrice"
              value={kidPrice}
              onChange={handleKidPriceChange}
            />
          </div>
        </div>
        <button type="submit">Update Prices</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default TicketPrices;
