import React, { useState, useEffect } from 'react';

const BalanceSheet = ({ user_type }) => {
  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [filter, setFilter] = useState('');
  const [dateRange, setDateRange] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // State for pagination
  const [error, setError] = useState(null);

  const itemsPerPage = 20; // Number of items to show per page

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await fetch('https://bijuwarsp.bluebugsoft.com/backend/get_sessions.php');

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        data.sort((a, b) => new Date(b.start_time) - new Date(a.start_time)); // Sort data from latest to oldest
        setSessions(data);
        applyFilters(data);
      } catch (error) {
        console.error('Error fetching sessions:', error);
        setError(error.message || 'An error occurred while fetching sessions.');
      }
    };

    fetchSessions();
  }, []);

  const applyFilters = (sessionsToFilter) => {
    let filtered = sessionsToFilter;

    // Apply date range filter
    const now = new Date();
    let start = new Date('1900-01-01');
    let end = now;

    switch (dateRange) {
      case 'week':
        start = new Date();
        start.setDate(start.getDate() - 7);
        break;
      case 'month':
        start = new Date();
        start.setMonth(start.getMonth() - 1);
        break;
      case '3months':
        start = new Date();
        start.setMonth(start.getMonth() - 3);
        break;
      case '6months':
        start = new Date();
        start.setMonth(start.getMonth() - 6);
        break;
      case 'year':
        start = new Date();
        start.setFullYear(start.getFullYear() - 1);
        break;
      case 'custom':
        start = new Date(startDate);
        end = new Date(endDate);
        break;
      default:
        start = new Date('1900-01-01');
        end = now;
    }

    filtered = filtered.filter(session => {
      const sessionStartTime = new Date(session.start_time);
      return sessionStartTime >= start && sessionStartTime <= end;
    });

    // Apply name filter
    filtered = filtered.filter(session =>
      session.name.toLowerCase().includes(filter.toLowerCase())
    );

    setFilteredSessions(filtered);
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilter(value);
    applyFilters(sessions);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handleDateRangeChange = (e) => {
    const range = e.target.value;
    setDateRange(range);
    applyFilters(sessions);
    setCurrentPage(1); // Reset to first page when date range changes
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDeleteSession = async (sessionId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this session? The deleted session would be added to trash and would be removed automatically after 30 days. Contact Bluebug Software for assisstance.');
    
    if (!confirmDelete) return; // Exit if the user cancels the deletion
    
    try {
      const response = await fetch(`https://bijuwarsp.bluebugsoft.com/backend/delete_session.php?id=${sessionId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete session.');
      }

      // Remove the deleted session from the state
      const updatedSessions = sessions.filter(session => session.id !== sessionId);
      setSessions(updatedSessions);
      applyFilters(updatedSessions);
    } catch (error) {
      console.error('Error deleting session:', error);
      setError('An error occurred while deleting the session.');
    }
  };

  const formatPrice = (price) => {
    const numericPrice = parseFloat(price);
    return isNaN(numericPrice) ? '0.00' : numericPrice.toFixed(2);
  };

  // Convert GMT to Nepali Time (GMT+5:45)
  const toNepaliTime = (dateString) => {
    const date = new Date(dateString);
    const offset = 5.75 * 60 * 60 * 1000; // 5 hours and 45 minutes in milliseconds
    return new Date(date.getTime() + offset).toLocaleString();
  };

  // Calculate the data to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentSessions = filteredSessions.slice(startIndex, startIndex + itemsPerPage);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredSessions.length / itemsPerPage);

  return (
    <div className='overflow-y page-overflow page-balance-sheet'>
      
      <h2 className='section-heading'>Balance Sheet</h2>
      <p className='sub-heading'>Filter the data by Name or by Time period.</p>

      <div className='select-filter'>
        <input
          type="text"
          placeholder="Filter by name..."
          value={filter}
          onChange={handleFilterChange}
        />
        <select value={dateRange} onChange={handleDateRangeChange}>
          <option value="">Select Date Range</option>
          <option value="week">Last Week</option>
          <option value="month">Last Month</option>
          <option value="3months">Last 3 Months</option>
          <option value="6months">Last 6 Months</option>
          <option value="year">Last Year</option>
          <option value="custom">Custom Range</option>
        </select>
        {dateRange === 'custom' && (
          <div>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        )}
      </div>

      {error && <p className="error">{error}</p>}

      <table>
        <thead>
          <tr>
            <th>SN</th>
            <th>Name</th>
            <th>Adults</th>
            <th>Kids</th>
            <th>Total Price</th>
            <th>Start Time</th>
            <th>End Time</th>
            {user_type === '1' && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {currentSessions.length > 0 ? (
            currentSessions.map((session, index) => (
              <tr key={session.id}>
                <td>{startIndex + index + 1}</td> {/* SN based on current page and index */}
                <td>{session.name}</td>
                <td>{session.adults}</td>
                <td>{session.kids}</td>
                <td>{formatPrice(session.total_price)}</td>
                <td>{toNepaliTime(session.start_time)}</td>
                <td>{toNepaliTime(session.end_time)}</td>
                {user_type === '1' && (
                  <td>
                    <button
                      onClick={() => handleDeleteSession(session.id)}
                      className="delete-button"
                    >
                      Delete
                    </button>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={user_type === '1' ? 8 : 7}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination Controls */}
      {totalPages > 1 && (
        <div className="pagination">
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i + 1}
              className={`page-button ${currentPage === i + 1 ? 'active' : ''}`}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </button>
          ))}
        </div>
      )}

      {/* Inline styling for pagination and delete button */}
      <style>
        {`
          .user-type {
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: bold;
          }

          .pagination {
            display: flex;
            justify-content: center;
            margin: 20px 0;
          }
          
          .page-button {
            background-color: #007bff;
            border: none;
            color: white;
            padding: 10px;
            margin: 0 5px;
            cursor: pointer;
          }
          
          .page-button.active {
            background-color: #0056b3;
          }
          
          .delete-button {
            background-color: #dc3545;
            border: none;
            color: white;
            padding: 5px 10px;
            cursor: pointer;
          }
          
          .error {
            color: red;
            font-weight: bold;
          }
        `}
      </style>
    </div>
  );
};

export default BalanceSheet;
